
import { encode, decode } from "js-base64";
import { defineComponent, reactive, ref } from "vue";

export default defineComponent({
  name: "Test",
  setup() {
    const encode1 = ref(
      "aHR0cHM6Ly93d3cuaTEyMzY4LmNvbS93ZWIvL2ZpbGVzLzIwMjEvNTExMzA0LzIwMjEwMTA5MjM1MDAwMDAwMDYvZGVsaXZlcnkvYTIzZjBmZDYxY2YyNDdhZDg0MzY3YmY4NmJiOGY4YTlfJUU0JUJDJUEwJUU3JUE1JUE4JUVGJUJDJTg4JUU1JTkwJTg0JUU3JUIxJUJCJUU2JUExJTg4JUU0JUJCJUI2JUU5JTgwJTlBJUU3JTk0JUE4JUVGJUJDJTg5LTEoJUU1JTk0JTkwJUU1JTg3JUE0KS5wZGY="
    );
    const encode2 = ref();
    const encode3 = ref();

    const encodeFun = () => {
      
      encode2.value = encode(encode1.value);
    };
    const decodeFun = () => {
      encode3.value = decode(encode2.value);
    };
    return {
      encode1,
      encodeFun,
      decodeFun,
    };
  },
});
